import React, {useState, useEffect} from "react";
import { Row, Col, Breadcrumb, Button} from 'antd';
import { Link } from "gatsby";

import Layout from '../../components/layout';
import {ALL_SERVICES, WEB_APP_URL} from '../../constant';
// import "./style.less";
import FooterBox from '../../components/footerBox';
import Routes from "../../constant/routes";

const ServiceTemplate = ({title, description, subItem, image, metaDescription, url="submit-ticket"}) => {
  const [relatedServices, setRelatedServices] = useState([]);
  
  useEffect(() => {
    const path = window?.location.pathname;
    const services = ALL_SERVICES.filter(s => s.route !== path);
    setRelatedServices(services)
  }, []);

  return (
    <Layout style={{paddingTop: 0}} title={`TicketTamer - ${title}`} description={metaDescription}>
    <div className="Services__Background-Image">
        <Row className="max-width-container">
          <Col md={16} xs={24}>
            <Breadcrumb>
              <Breadcrumb.Item>
              <Link to={Routes.HOME}>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Services</Breadcrumb.Item>
              <Breadcrumb.Item>{title}</Breadcrumb.Item>
            </Breadcrumb>
            
            <div className="DisplayBlackLeft Services__Title mt8 mb40">{title}</div>
            <div className="Service_Banner_Btns">
              <a className="Service_Banner_Btn" href={`${WEB_APP_URL}/${url}`}><Button type="primary" block>Get Instant Quote</Button></a>
              <a className="Service_Banner_Btn" href="https://calendly.com/tickettamer/15min?month={YYYY-MM}" target="_blank" rel="noreferrer"><Button block>Schedule Free Consultation</Button></a>
            </div>
          </Col>
          <Col md={8} xs={24}>
            <img src={image} alt="banner" className="Services__Image for-desktop" />
          </Col>
        </Row>
      </div>
      <div className="max-width-container">
        <Row justify="center">
          <Col md={24}>
            <div>
              <div className="LeadingBlackLeft Services__Description PreText">{description}</div>
              {subItem.map((i, idx) => (<div key={`service_sub_${idx}`}>
                <div className="H1BlackLeft mt40">{i.title}</div>
                <div className="LeadingBlackLeft mt24 PreText">{i.text}</div>
              </div>))}
            </div>
          </Col>
        </Row>
        <div className="H3BlackLeft mt80 mb40">Related Services</div>
        <Row gutter={[24, 24]}>
        {relatedServices.map((s, idx) => (<Col key={`related_service_${idx}`} md={8} xs={24}><Link to={s.route} className="Link" style={{fontSize: 16}}>{s.title}</Link></Col>))}
        </Row>
      </div>
      <br />
      <FooterBox title={title} url={url} />
    </Layout>
  );
}

export default ServiceTemplate;
