import React from "react";

import CDLImg from '../../images/services/CDL.png';
import ServiceTemplate from "./ServiceTemplate";

const SubItem = [];

const CDLLawyers = (props) => {
  return <ServiceTemplate 
  title="CDL Lawyers" 
  description={`Don’t let greedy state and local governments put your CDL license at risk!

TicketTamer specializes in helping truckers stay on the road when you get a traffic ticket, and you can do it all on your phone with our TicketTamer website. Most tickets $300.

We will get your ticket reduced to a non-moving violation, keeping your record clean and your insurance rates down. We serve most states, and are not a referral service.

Free DataQs/CSA challenges to help our friends on the road.`}
  subItem={SubItem}
  image={CDLImg}
  url="submit-speeding-ticket"
  metaDescription="Submit your CDL ticket - Get your quote instantly - Schedule your free consultation"
  />;
}

export default CDLLawyers;
